.Faqs {
  display: flex;
  flex-direction: column;
 width: 80vw;
  height: 100vh;
}

.Accordion-header {
  font-family: "DM Serif Display", serif;
  font-weight: bold;
    
}