.selected-product-container {
    background-color: rgb(255, 254, 254);
    display: flex;
    align-items: center;
    flex-direction: row;
}

.product-image-description {
   width: 40vw;
   max-width: auto;
}

.product-description-container  {
  padding: 2rem;
}

#title-description {
    border-bottom: 1px solid black;

}


@media(max-width:800px) {
  .selected-product-container {
    flex-direction: column;
  }

  .product-image-description{
    width: 100vw;
  }
}