@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border: 1px solid lightgray;
}

header p {
  font-size: 1.5rem;
  font-style: oblique;
  color: black;
  font-weight: bold;
}

header .logo {
  margin-left: 3rem;
  height: 3rem;
  width: auto;
}

header nav {
  z-index: 1;
}

nav a {
  padding: 0.25rem;
  margin-left: 5rem;
  color: rgb(87, 86, 86);
  position: relative;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: bold;
}

nav a:hover {
  text-decoration-color: white;
}

nav a:hover::before {
  transform: scaleX(1);
}

nav a::before {
  content: "";
  width: 100%;
  position: absolute;
  display: block;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: rgb(73, 73, 239);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease ;
}

header .nav-btn {
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.5rem;
  color: gray;
}

@media only screen and (max-width: 800px) {

  nav a {
    margin-left: 0rem;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header .navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    transition: 1s;
    transform: translatex(100vh);
    background-color: white;
  }

  header .responsive-nav {
    transform: none;
    background-color: rgba(59, 59, 235, 0.729);

  }

   header .responsive-nav a {
    font-size: 1.5rem;
    color: white;
  }

  nav .nav-close-btn {
    color: white;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  header .logo {
    height: 2rem;
    margin-left: 1rem;
  }
}
