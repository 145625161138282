.carrers-container {
    background-size: cover;
    height: 70vh;
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    

}



.vacante {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    border: 1px solid lightgray;
    border-radius: 10px;
    width: 35vw;
    background-color: white;
padding: 1rem;
}

.vacante p {
    font-style: oblique;
}


.vacante:hover {
    cursor: pointer;
    background-color:  lightgray;
    border: 2px solid lightgray;
}

@media(max-width: 1200px) {
    .carrers-container{
        height: 100vh;
    }
    .vacante {
        width: 100vw;
        padding: 1rem;

    }
}
