

.category {
  background-image: url("../assets/pexels-castorly-stock-4058111.jpg");
  background-size: cover;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;

 }

 .category h1 {
  font-family: "DM Serif Display", serif;
  padding: 1rem;
  color: white;
  font-size: 4rem;
 }

.company-list-container p {
  font-size: 1.3rem;
  font-weight: bold;
}

.company-list-container a {
  font-size: 1.3rem;
  font-weight: bold;
}


.product-container {
  margin: 1rem;
}

.product-image {
  width: 25vw;
  height: 50vh;
}

.product-title {
  height: 3rem;
  font-weight: bolder;
  font-style: oblique;
  border-bottom: 1px solid lightgray;
}

@media(max-width: 800px){
 
  .product-container {
    margin: 0;
  }
  .product-image{  
    height: 40vh;
    width: 90vw  }
}