.Banner3-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 1rem;


}

.Banner3-section {
  padding: 2rem;
}
 
.Banner3-section p {
  font-size: 1.3rem;
  font-weight: bolder;
}
.Banner3-section span {
  font-size: 1.2rem;
}



#scan-icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}

#search-icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}
#shield-icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}


@media (max-width: 800px) {
  .Banner3-container  {
    flex-direction: column;
  }

 
  .Banner3-section p {
    font-size: 1rem;
    font-weight: bolder;
  }
  .Banner3-section span {
    font-size: 0.9rem;
  }
  

  .Banner3-container .Banner3-section {
    width: 100vw;
    padding: 1rem;
  

  }
}
