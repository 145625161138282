.vacantes-container {
  padding: 1rem;
  margin: 1rem, 0;
}

.vacantes-item {
  display: flex;
  flex-direction: column;
}

.experiencia-laboral_item_1 {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color:  lightgray;
  padding: 1rem;
  margin-top: 1rem;
}

.experiencia-laboral_item_2 {
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 1rem;
}

.experiencia-laboral_item_3 {
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 1rem;
}

#form-data {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#form-data select {
  padding: 0.5rem;
  font-weight: bold;
}

 #form-data option {
   padding: 0.5rem;
   font-weight: bold;
 }

#form-data p {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  background-color: #0d6efd;
  color: white;
}
#form-data input {
  font-weight: bolder;
  padding: 0.3rem;
  border-radius: 0.2rem;
  border: 1px solid gray

}

#form-data input:focus {
  outline: none;
  border: 1px solid lightblue;

}

#disponible-checkbox {
  font-style: oblique;
  padding: 1rem;
}

textarea {
  border: 1px solid lightgray;
}

textarea:focus {
  outline: none;
}

#submit {
  margin-top: 1rem;
}

#submit:hover {
  background-color: #0d6efd;
  color: white;
}

@media (max-with: 1200px) {
  .vacante-info {
    flex-direction: row;
    font-size: 0.8rem;
    width: 100vw;
  }

  .vacante-description {
    width: 100vw;
  }
  .vacantes-container {
    flex-direction: column;
  }
}
