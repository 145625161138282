@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");

.Banner {
  background-image: url("./assets/pharmacist-checking-medicines-drugstore2.jpg");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.Banner h1 {
  color: white;
  padding: 1rem;
  font-size: 4rem;
  width: 50vw;
  margin-left: 1rem;
}

.Banner #health {
  border-bottom: 4px solid white;
}
.Banner .banner-paragraph {
  font-size: 1.5rem;
  font-style: oblique;
  color: white;
  padding: 1rem;
  width: 50vw;
  margin-left: 1rem;
}
.Banner .banner-see-more {
  cursor: pointer;
  margin-left: 1rem;
  padding: 0.5rem;
  color: whitesmoke;
  border: 2px solid whitesmoke;
  width: 6.5rem;
}

@media (max-width: 800px) {
  .Banner {
    height: 60vh;
  }
  .Banner h1 {
    font-size: 3rem;
    color: white;
    padding: 1rem;
    width: 100vw;
  }

  .Banner .banner-paragraph {
    font-size: 1.2rem;
    padding: 1rem;
    width: 100vw;
  }

  .Banner #dutyfree-slogan {
    font-size: 3rem;
  }
  .Banner .banner-see-more {
    display: none;
  }
}
