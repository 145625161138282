.contact-us_container {
  display: flex;
  flex-direction: row;

}

#contact-us_form {
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#contact-us_form input {
  border: 2px solid lightgray;
}


.contact-us_office_Info_container {
  padding: 1rem;

}

@media(max-width: 1200px) {
  .contact-us_container{
    flex-direction: column-reverse;
  }

  #contact-us_form {
    width: 100vw;
    padding: 0.5rem;
  }
}