

#mex-product-image {
    width: 25vw;
    height: 50vh;
    border: 1px solid whitesmoke;
    padding: 1rem;
}

#mex-product-title {
    height: 2rem;
    font-weight: bolder;
    font-style: oblique;
    border-bottom: 1px solid lightgray;
}


@media(max-width: 800px) {
    #mex-product-image {
        width: 100vw;
        height:40vh;
    }
}