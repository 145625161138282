#anabolics-product-image  {
    width: 20vw;
    height: 35vh;
}


@media(max-width: 800px){
 
    #anabolics-product-image  {
        width: 90vw;
        height: 40vh;
    }
  }