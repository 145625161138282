/*FOOTER MENU STYLES*/

.page-section-dutyfree-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(223, 223, 223);
  padding: 2rem;
}

.dutyfree-description {
  width: 70vw;
}
.dutyfree-description p {
  font-size: 1.3rem;
}

#duty-free-hand {
  width: 3rem;
  height: 3rem;
}
.company-list-container {
  display: flex;
  flex-direction: row;
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.company-list-container .menu {
  flex-direction: column;
  border-left: 3px solid lightgray;
  margin-left: 1rem;
}

.company-list-container .menu a {
  text-decoration: none;
}

@media (max-width: 800px) {
  .page-section-dutyfree-info {
    flex-direction: column;
  }

  .dutyfree-description {
    padding: 1rem;
    width: 100vw;
  }

  #duty-free-hand {
    width: 2rem;
    height: 2rem;
  }

  .company-list-container {
    padding: 1rem;
  }

  .company-list-container .menu p {
    display: none;
  }
  .company-list-container .menu {
    align-items: center;
    justify-content: center;
  }
  .company-list-container .menu a {
    padding: 0.5rem;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .company-list-container .menu a:hover {
    color: black;
    cursor: pointer;
  }
}

footer {
  text-align: center;
  background-color: rgb(223, 223, 223);
  font-size: 0.8rem;
}

#product-image {
  width: 50vw;
}

.show-product {
  display: flex;
  flex-direction: row;
}

.show-product-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
