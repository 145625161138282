.loading-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100vh;
   width: 100vw;
}

#loading-logo {
    margin-bottom: 2rem;
   margin-right: 2.5rem;
    height: 4rem;
}