/*ABOUT US PAGE STYLES */

.about-us_items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us_text {
  width: 60vw;
}

/*OUR MISSION STYLES SECTION*/

#nurse-image {
  max-width: 100%;
  height: auto;
}

/*CUSTOMER PROTECTION POLICY SECTION*/

#privacy-policy-icon {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}

/*COMPANY MISSION SECTION*/

#lab {
  width: 400px;
  height: 500px;
  border-radius: 10px;
}

#company-mission-icon {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}

/*OUR PHILOSOPHY SECTION*/

#our-philosophy-icon {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}

/*OUR SERVICE SECTION*/

#our-service-icon {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}

#pharmacist-checking-medicines {
  height: 50vh;
  max-width: auto;
}

@media (max-width: 1200px) {
  .about-us_items {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 2px solid lightgray;
    border-radius: 8px;
    margin-top: 1rem;
  }

}
