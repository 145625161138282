#user-icon {
  color: white;
}

.dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-image: url("../components/assets/watercolor-texture-background.webp");
  background-size: cover;
  height: 15vh;
}

.dashboard a {
  position: relative;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}

.dashboard a:hover {
  text-decoration-color: white;
}

.dashboard a:hover::before {
  transform: scaleX(1);
}

.dashboard a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

@media (max-width: 800px) {
  .dashboard {
    padding: 0.8rem;
  }
  .dashboard a {
    font-size: 0.8rem;
    padding: 1rem;
  }
}